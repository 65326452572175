import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import DOMPurify from 'dompurify';

import { navigate } from "gatsby";
import Helmet from "react-helmet"

const APOLLO_QUERY_PARAGRAPH1 = gql`
  {
  	  hiremes(where: {id: 29}) {
	    nodes {
	      content
	      title
	    }
	  }

  }
`

const APOLLO_QUERY_PARAGRAPH2 = gql`
  {
  	 hiremes(where: {id: 31}) {
	    nodes {
	      content
	      title
	    }
	  }

  }
`

const APOLLO_QUERY_PARAGRAPH3 = gql`
  {
  	 hiremes(where: {id: 30}) {
	    nodes {
	      content
	      title
	    }
	  }

  }
`

const APOLLO_QUERY_PARAGRAPH4 = gql`
  {
  	 hiremes(where: {id: 32}) {
	    nodes {
	      content
	      title
	    }
	  }

  }
`

let content1 = <div style={{ padding:'30% 45%' }}><CircularProgress color="inherit" /></div>;
let content2 = <div style={{ padding:'30% 45%' }}><CircularProgress color="inherit" /></div>;
let content3 = <div style={{ padding:'30% 45%' }}><CircularProgress color="inherit" /></div>;
let content4 = <div style={{ padding:'30% 45%' }}><CircularProgress color="inherit" /></div>;

const sanitizer = DOMPurify.sanitize;

const IndexPage = () => {

	useEffect(() => {
		
		// document.body.style.backgroundColor = "rgba(0, 128, 0, 0.1)";

	})

	const goToWork = () => {

		navigate('/work/');
	}

	const goToContact = () => {

		navigate('/contact/');
	}

	return (
	  <Layout>
	    <SEO title="Hire Me" />

	    <Helmet>
            {/* <!-- Primary Meta Tags --> */}
            <title>Need help from an experienced copywriter?</title>
            <meta name="title" content="Need help from an experienced copywriter?" />
            <meta name="description" content="Need an experienced and reliable freelance copywriter offering copywriting and copyediting services at reasonable rates? For jargon-free, clear copy, give me a call." />
        </Helmet>

	    <Grid container className="main">

	    	<Grid container className="hirepage">
	    		<h1>What I can DO FOR YOU.</h1>

	    		<Grid container className="hireme">
	    		
		    		<Grid item className="hire_text">

			    		 <Query query={APOLLO_QUERY_PARAGRAPH1}>

		                      {({data}) => {

		                      if(data){

		                      	content1 = <div dangerouslySetInnerHTML={{ __html: sanitizer(data.hiremes.nodes[0].content)}} />
		                      	
		                      }

		                      return content1

		                    }}

		                </Query>

		                {/*<Grid onClick={goToContact} item className="black_button button_small">Let's collaborate!</Grid>*/}
			    		
					</Grid>

					<Grid id="copywriting" style={{margin:'20px 0 0 0'}} onClick={goToContact} item className="black_button button_small">Let's collaborate!</Grid>

				</Grid>



				<h2>What services do i offer?</h2>

	    		<Grid container className="hireme" id="copywriting">
	    		
		    		<Grid item className="hire_text">
		    		
		    			<h1>copywriting</h1>
		    			
			    		<Query query={APOLLO_QUERY_PARAGRAPH3}>

		                      {({data}) => {

		                      if(data){

		                      	content2 = <div dangerouslySetInnerHTML={{ __html: sanitizer(data.hiremes.nodes[0].content)}} />
		                      	
		                      }

		                      return content2

		                    }}

		                </Query>

			    		{/*<Grid onClick={goToWork} item className="black_button button_small">View my work</Grid>*/}

					</Grid>
					
					<Grid id="copyediting" style={{margin:'20px 0 0 0'}} onClick={goToWork} item className="black_button button_small">View my work</Grid>
					
				</Grid>


				


	    		<Grid container className="hireme padded">
	    			
		    		<Grid item className="hire_text">

		    			<h2>copy editing</h2>
		    		
					
						<Query query={APOLLO_QUERY_PARAGRAPH2}>

		                      {({data}) => {

		                      if(data){

		                      	content3 = <div dangerouslySetInnerHTML={{ __html: sanitizer(data.hiremes.nodes[0].content)}} />
		                      	
		                      }

		                      return content3

		                    }}

		                </Query>

			    		{/*<Grid onClick={goToWork} item className="black_button button_small">View my work</Grid>*/}

					</Grid>

					{/*<Grid style={{margin:'20px 0 0 0'}} onClick={goToWork} item className="black_button button_small">View my work</Grid>*/}

				</Grid>


				<h2>What Do I Charge?</h2>

	    		<Grid container className="hireme">
	    		
		    		<Grid item className="hire_text">

			    		{/*<p>I price my work per project. Please get in touch to discuss the brief and I'll give you a quote.</p>*/}

			    		<Query query={APOLLO_QUERY_PARAGRAPH4}>

		                      {({data}) => {

		                      if(data){

		                      	content4 = <div dangerouslySetInnerHTML={{ __html: sanitizer(data.hiremes.nodes[0].content)}} />
		                      	
		                      }

		                      return content4

		                    }}

		                </Query>

					</Grid>

				</Grid>



	    	</Grid>

	    </Grid>
	    
	  </Layout>
	)

}

export default IndexPage
